import React, { useState, useRef } from "react";
import parse from "html-react-parser";
import _ from "lodash";
import "../../../../../tailwind.generated.css";
import clsx from "clsx";

import TestBranding from "./TestBranding";

import { PreferenceBlock, Block, FirstClickBlock } from "../../../../../models/Test";
import { IconImage, IconCheck, IconCancel, IconQuestionMark, IconInfo, IconShow } from "../../../../../icons";
import Button from "../../../../Button";
import FirstClickPointer from "../../../../Test/FirstClickPointer";

import { getFirstClickCoordinates } from "../../../../../utils";

import useIsMobile from "../hooks/useIsMobile";

import { locale, interfaceText } from "../../../../../helpers";
import TaskSidebar from "../TaskSidebar";

import { motion } from "framer-motion";
import Modal from "../../../../Modal";

import useDimensions from "../../../../Common/Hooks/useDimensions";

import ShowTaskButton from "./ShowTask";

type TaskProps = {
  blockData: FirstClickBlock & Block;
};

function Task(props: TaskProps) {
  return (
    <div>
      <div className="text-lg font-medium flex-shrink-0 break-words flex flex-col w-full">
        <div>{parse(props.blockData.questionHtml || props.blockData.text)}</div>
      </div>
    </div>
  );
}

type FirstclickProps = {
  blockData: FirstClickBlock & Block;
  blockAnswer: any;
  handleBlockAnswer: any;
  nextStepOnClick: any;
  withProgress?: boolean;
  totalSteps?: number;
  currentStep?: number;
  responseStart?: any;
  setResponseStart?: any;
  isAnswerValid: boolean;
};

function Firstclick(props: FirstclickProps) {
  const [startTime, setStartTime] = useState<null | number>(null);
  const isMobile = useIsMobile();

  const [imageReady, setImageReady] = useState(false);

  const [isTaskModalOpen, setIsTaskModalOpen] = useState(true);

  const taskStarted = !!props.responseStart;
  const isValid = props.blockAnswer?.selectedOptions && props.blockAnswer?.selectedOptions.length > 0;

  const taskContainerRef = useRef<HTMLDivElement | null>(null);
  const imageContainerRef = useRef<HTMLImageElement | null>(null);

  const position = props.blockAnswer.clickData;

  const { height: taskContainerHeight } = useDimensions({ ref: taskContainerRef });

  return (
    <>
      <div className="firstclick relative flex-1 flex-col h-full w-full overflow-y-hidden">
        {taskStarted && (
          <ShowTaskButton
            openTaskModal={() => {
              if (!position) {
                setIsTaskModalOpen(true);
              }
            }}
            buttonName={(interfaceText as any).test[locale()].showTask}
          />
        )}
        <div className={clsx("firstclick__content flex flex-1 flex-col h-full overflow-y-auto w-full relative")} ref={taskContainerRef}>
          <div className={`flex justify-center`}>
            <div className={clsx("firstclick__pointer-layer relative inline-block")} ref={imageContainerRef}>
              {position && (
                <FirstClickPointer
                  imageContainerRef={imageContainerRef}
                  withPopover={true}
                  position={position}
                  opacity={100}
                  popoverContentBlock={
                    <div className="flex flex-col items-center justify-center">
                      <Button
                        name={""}
                        handler={props.nextStepOnClick}
                        icon={<IconCheck width={24} height={24} className="fill-current text-white" />}
                        small
                        disabled={!props.isAnswerValid}
                        className="mb-2"
                      />
                      <Button
                        small
                        type="ghost"
                        name={""}
                        icon={
                          <IconCancel
                            width={20}
                            height={20}
                            className="fill-current text-gray-600 hover:text-gray-800 transition-all ease-in duration-75"
                          />
                        }
                        handler={() => {
                          props.handleBlockAnswer("clickData", null);
                        }}
                      />
                    </div>
                  }
                />
              )}
              <img
                decoding="sync"
                id="firstClickImage"
                onClick={(e) => {
                  if (!position && startTime) {
                    props.handleBlockAnswer("clickData", {
                      left: getFirstClickCoordinates(e).left,
                      top: getFirstClickCoordinates(e).top,
                      responseTime: _.now() - startTime,
                    });
                  }
                }}
                onLoad={() => {
                  setImageReady(true);
                }}
                src={props.blockData.image}
                alt=""
                className={clsx(
                  "lg:mt-0 max-w-full transition-all duration-150 ease-in",
                  position && "filter brightness-60"
                )}
                style={props.blockData.scaling === "scale-down" ? { maxHeight: taskContainerHeight } : undefined}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isTaskModalOpen}
        setIsOpen={setIsTaskModalOpen}
        width="440px"
        disallowClickOutside
        sidebarMode
        backdrop
      >
        <div className="flex flex-col gap-4 p-6 h-full justify-between">
          <div className="flex flex-col gap-4">
            <TestBranding />
            <Task blockData={props.blockData} />
            <p>{(interfaceText as any).test[locale()].firstClickInstruction}</p>
          </div>
          <div className="flex justify-end">
            <Button
              name={(interfaceText as any).firstclickTesting[locale()].showImage}
              handler={() => {
                setStartTime(_.now());
                props.setResponseStart(_.now());
                setIsTaskModalOpen(false);
              }}
              large
              icon={<IconImage width={20} height={20} className="fill-current text-white mr-2" />}
              disabled={!imageReady}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Firstclick;
