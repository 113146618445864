import React, { useState, useRef, useEffect, ChangeEvent, FormEvent } from "react";
import "../tailwind.generated.css";
import clsx from "clsx";
import { IconClose } from "../icons";

export interface InputProps {
  title?: string;
  placeholder?: string;
  initialValue?: string;
  onBlur?: (value: string) => void;
  onChange?: (value: string) => void;
  onFocus?: (value: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  id?: string;
  className?: string;
  large?: boolean;
  focusOnMount?: boolean;
  selectAllOnFocus?: boolean;
  validate?: (value: string) => boolean;
  validationError?: string;
  disabled?: boolean;
  updateFromInitialValue?: boolean;
  maxLength?: number;
  hasClearIcon?: boolean;
}

const Input: React.FC<InputProps> = ({
  title = "",
  placeholder = '',
  initialValue,
  onBlur,
  onKeyDown,
  onChange,
  onFocus,
  id,
  className = '',
  large = false,
  focusOnMount = false,
  selectAllOnFocus = false,
  validate,
  validationError,
  disabled = false,
  updateFromInitialValue = false,
  hasClearIcon = false,
  maxLength,
}) => {
  const [value, setValue] = useState<string>(initialValue || '');
  const [isValid, setIsValid] = useState<boolean>(true);

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (value.length > 0 && validate) {
      setIsValid(validate(value));
    }
  }, [value, validate]);

  useEffect(() => {
    if (updateFromInitialValue === true) {
      setValue(initialValue || '');
    }
  }, [updateFromInitialValue, initialValue]);

  useEffect(() => {
    if (focusOnMount && id) {
      const element = document.getElementById(id) as HTMLInputElement;
      element?.focus();
      if (selectAllOnFocus) {
        element?.select();
      }
    }
  }, [focusOnMount, selectAllOnFocus, id]);

  function handleChange(newValue: string) {
    if (onChange) {
      onChange(newValue);
    }
    setValue(newValue);
  }

  return (
    <>
      {title && <div className="my-1 text-sm font-medium">{title}</div>}
      <input
        disabled={disabled}
        ref={textareaRef as any}
        draggable="false"
        className={clsx(className && className, large && "text-xl", disabled && "opacity-50 cursor-not-allowed", "w-full")}
        style={{ overflowY: "hidden" }}
        id={id}
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleChange(e.target.value);
        }}
        onBlur={() => {
          if (onBlur) {
            onBlur(value);
          }
          if (validate) {
            setIsValid(validate(value));
          }
        }}
        onFocus={() => {
          if (selectAllOnFocus && id) {
            const element = document.getElementById(id) as HTMLInputElement;
            element?.select();
          }
          if (onFocus) {
            onFocus(value);
          }
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (onKeyDown) {
            onKeyDown(e);
          }

          if (e.key === 'Enter') {
            if (onBlur) {
              onBlur(value);
            }
          }
        }}
        onInput={(e: FormEvent<HTMLInputElement>) => {
          const target = e.target as HTMLInputElement;
          if (textareaRef.current && (textareaRef.current?.parentNode as HTMLElement)?.dataset) {
            (textareaRef.current.parentNode as HTMLElement).dataset.replicatedValue = target.value;
          }
        }}
      />
      {hasClearIcon && value && <button onClick={() => {
        handleChange('')
      }}>
        <IconClose className="w-5 h-5 fill-current text-gray-500 mr-1" />
      </button>}
      {validationError && !isValid && <div className="text-red-600 text-sm font-medium">{validationError}</div>}
    </>
  );
};

export default Input;

