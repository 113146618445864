import React, { PropsWithChildren } from "react";

import { IArea } from "../models";

export default function Clickarea(props: PropsWithChildren<{
	onClick: (area: IArea) => void,
	area: IArea,
	scaleRatio: number,
}>) {
	const area = props.area;
	

	return <div
		onClick={() => props.onClick(area)}
		className="clickarea absolute border-2 bg-opacity-25 bg-blue-400 border-blue-600 rounded-md cursor-pointer select-none"
		style={{
			borderWidth: Math.round(2 / props.scaleRatio),
			top: area.top + 'px',
			left: area.left + 'px',
			width: area.width + 'px',
			height: area.height + 'px',
			position: "fixed",
		}}
	>
		<div className="clickarea__info absolute text-xs text-white rounded-md p-1 m-1 font-bold" style={{ transform: `scale(${1 / props.scaleRatio})`, transformOrigin: "0 0" }}>
			{props.children}
		</div>
	</div>
}