import React, { useContext } from "react";
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from "react-i18next";
import "../tailwind.generated.css";

import { auth } from "../firebase";
import AppContext, { DefaultAppState } from "../AppContext";

import Button from "./Button";

const SignOut = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { dispatch } = useContext(AppContext);

  return (
    <Button
      handler={() => {
        auth.signOut().then(() => {
          queryClient.clear();
          dispatch({ type: 'RESET_STATE', payload: DefaultAppState});
        });
      }}
      name={t("Sign out")}
      type="secondary"
    />
  );
};

export default SignOut;
